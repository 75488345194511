import Cookies from "js-cookie";

document.addEventListener('DOMContentLoaded', function() {
    var cookieconsent = initCookieConsent();

    cookieconsent.run({
        current_lang : 'fr',
        autoclear_cookies: true,
        cookie_domain: location.hostname,       // default: current domain
        cookie_path: '/',
        page_scripts: true,
        gui_options: {
            consent_modal : {
                layout : 'box',               // box/cloud/bar
                position : 'bottom right',     // bottom/middle/top + left/right/center
                transition: 'slide'             // zoom/slide
            },
            settings_modal : {
                layout : 'box',                 // box/bar
                // position : 'left',           // left/right
                transition: 'slide'             // zoom/slide
            }
        },

        // si les cookies sont acceptés (ne pas confondre avec le fait de cliquer sur accepter)
        onAccept : function (cookie) {
            document.querySelector('a.cookies-button').classList.add('is-visible');
        },
        onFirstAction: function (cookie) {
            window.location.reload();
        },
        onChange: function (cookie) {
            window.location.reload();
        },

        languages : {
            fr : {
                consent_modal : {
                    title :  "Votre commune respecte votre vie privée",
                    description : "Sur ce site, nous utilisons des cookies pour mesurer l’audience et améliorer votre expérience de navigation. Vous pouvez sélectionner ici les services que vous allez autoriser. <a aria-label='Je préfère choisir' data-cc='c-settings' class='cc-link' href='javascript:void(0);' data-focus='0' aria-haspopup='dialog'>Je préfère choisir</a> - <a aria-label='Politique de confidentialité' class='cc-link' href='/politique-de-confidentialite'>Politique de confidentialité</a>",


                    primary_btn: {
                        text: "J'accepte",
                        role: 'accept_all'  //'accept_selected' or 'accept_all'
                    },
                    secondary_btn: {
                        text : "Je refuse",
                        role : 'accept_necessary'   //'settings' or 'accept_necessary'
                    }
                },
                settings_modal : {
                    title : 'Gestion de préférence des cookies',
                    save_settings_btn : "Enregistrer",
                    accept_all_btn : "Tout accepter",
                    reject_all_btn : "Tout rejeter", // optional, [v.2.5.0 +]
                    close_btn_label: "Fermer",
                    blocks : [
                        {
                            title : 'Votre commune respecte votre vie privée',
                            description: "Sur ce site, nous utilisons des cookies pour mesurer notre audience. Vous pouvez sélectionner ici les services que vous allez autoriser. <a aria-label='Politique de confidentialité' class='cc-link' href='/politique-de-confidentialite'>Politique de confidentialité</a>"
                        },{
                            title : "Cookies nécessaires au bon fonctionnement du site",
                            description: 'Ces cookies sont essentiels au bon fonctionnement du site, vous ne pouvez pas les désactiver.',
                            toggle : {
                                value : 'necessary',
                                enabled : false,
                                readonly: true
                            }
                        },{
                            title : "Analyses d’audience",
                            description: "Ces cookies nous permettent d'analyser les statistiques de consultation du site de votre commune. Ils sont utiles pour nous améliorer et faciliter votre accès au contenu. Ces données sont visibles par votre commune et la société française qui gère le site.",
                            toggle : {
                                value : 'analytics',
                                enabled : false,
                                readonly: false
                            }
                        },{
                            title : "Publicitaires (ou presque)",
                            description: "Pour concevoir ce site nous avons fait appel à une société bien de chez nous (à Nîmes pour être précis) et cette société s’appuie pour rendre votre site plus dynamique sur des technologies tierces : Google Analytics, Facebook Connect, YouTube, openmap, etc… Pour profiter de l’ensemble des technologies nous vous invitons à accepter ces cookies.",
                            toggle : {
                                value : 'advertising',
                                enabled : false,
                                readonly: false
                            }
                        },
                    ]
                }
            }
        }
    });

    // Acessibilité 
    // Ouverture du menu
    var jsAccessibilityOpen = document.querySelector('.jsAccessibilityOpen');

    jsAccessibilityOpen.addEventListener('click', function (e) {
        let element=document.querySelector('.accessibility');
        element.classList.add('active');
  
    });
    var jsAccessibilityClose = document.querySelector('.jsAccessibilityClose');

    jsAccessibilityClose.addEventListener('click', function (e) {
        let element=document.querySelector('.accessibility');
        jQuery(element).removeClass('active');
  
    });

    // Taille de la police
    let jsFontSizeMore = document.querySelector('.jsFontSizeMore');
    let onClickMore = document.querySelector('.jsFontSizeMore');
    let jsFontSizeLess = document.querySelector('.jsFontSizeLess');
    let onClickLess = document.querySelector('.jsFontSizeLess');
    let jsFonsizeReset = document.querySelector('.jsFontSizeReset');
    var clicks = 0;

    onClickMore.addEventListener('click', function(e) {
        while (clicks < 6) {            
            console.log(clicks);
            clicks += 1;
            return(clicks);
        }
    });
    onClickLess.addEventListener('click', function(e) {
        while (clicks >= 1) {
            clicks -= 1;
            console.log(clicks);
            return(clicks);
        }
    });

    jsFontSizeMore.addEventListener('click', function (e) {        
        let element=document.querySelector("html");
        switch (clicks) {
            case 1 : 
            element.classList.add('fontSizeMore1');
            Cookies.remove('accessibilityFontSize')
            break;
            case 2 : 
            element.classList.add('fontSizeMore2');            
            jQuery(element).removeClass('fontSizeMore1');
            Cookies.set('accessibilityFontSize', 'fontSizeMore2');
            break;
            case 3 : 
            element.classList.add('fontSizeMore3');
            jQuery(element).removeClass('fontSizeMore2');
            Cookies.set('accessibilityFontSize', 'fontSizeMore3');
            break;
            case 4 : 
            element.classList.add('fontSizeMore4');
            jQuery(element).removeClass('fontSizeMore3');
            Cookies.set('accessibilityFontSize', 'fontSizeMore4');
            break;
            case 5 :
            element.classList.add('fontSizeMore5');
            jQuery(element).removeClass('fontSizeMore4');
            Cookies.set('accessibilityFontSize', 'fontSizeMore5');
            break;
        }
    });

    jsFontSizeLess.addEventListener('click', function (e) {        
        let element=document.querySelector("html");
        switch (clicks) {
            case 0 :           
            jQuery(element).removeClass('fontSizeMore1');
            Cookies.remove('accessibilityFontSize')
            break;
            case 1 : 
            element.classList.add('fontSizeMore1');            
            jQuery(element).removeClass('fontSizeMore2');
            Cookies.set('accessibilityFontSize', 'fontSizeMore2');
            break;
            case 2 : 
            element.classList.add('fontSizeMore2');            
            jQuery(element).removeClass('fontSizeMore3');
            Cookies.set('accessibilityFontSize', 'fontSizeMore3');
            break;
            case 3 : 
            element.classList.add('fontSizeMore3');
            jQuery(element).removeClass('fontSizeMore4');
            Cookies.set('accessibilityFontSize', 'fontSizeMore4');
            break;
            case 4 : 
            element.classList.add('fontSizeMore4');
            jQuery(element).removeClass('fontSizeMore5');
            Cookies.set('accessibilityFontSize', 'fontSizeMore5');
            break;
        }   
    });

    if (Cookies.get('accessibilityFontSize')) {
        jQuery('html').addClass(Cookies.get('accessibilityFontSize'));
    }

    jsFonsizeReset.addEventListener('click', function(e) {
        resetFontSize();
    })

    // Acessibilité 
    // Choix des couleurs
    let jsColorNegative = document.querySelector('.jsColorNegative');
    let jsColorBW = document.querySelector('.jsColorNB');
    let jsColorWB = document.querySelector('.jsColorBN');

    jsColorNegative.addEventListener('click', function (e) {        
        let element=document.querySelector("html");
        if (!jQuery('html').hasClass('black_on_white') && !jQuery('html').hasClass('white_on_black')) {
            element.classList.add('negativeColor');
        }
        if (jQuery('html').hasClass('black_on_white')) {
            jQuery(element).removeClass('black_on_white');
            element.classList.add('negativeColor');
        }
        if (jQuery('html').hasClass('white_on_black')) {
            jQuery(element).removeClass('white_on_black');
            element.classList.add('negativeColor');
        }
        Cookies.set('accessibilityColor', 'negativeColor');
    });
    jsColorBW.addEventListener('click', function (e) {        
        let element=document.querySelector("html");
        if (!jQuery('html').hasClass('negativeColor') && !jQuery('html').hasClass('white_on_black')) {
            element.classList.add('black_on_white');
        }
        if (jQuery('html').hasClass('negativeColor')) {
            jQuery(element).removeClass('negativeColor');
            element.classList.add('black_on_white');
        }
        if (jQuery('html').hasClass('white_on_black')) {
            jQuery(element).removeClass('white_on_black');
            element.classList.add('black_on_white');
        }
        Cookies.set('accessibilityColor', 'black_on_white');
    });
    jsColorWB.addEventListener('click', function (e) {        
        let element=document.querySelector("html");
        if (!jQuery('html').hasClass('black_on_white') && !jQuery('body').hasClass('negativeColor')) {
            element.classList.add('white_on_black');
        }
        if (jQuery('html').hasClass('black_on_white')) {
            jQuery(element).removeClass('black_on_white');
            element.classList.add('white_on_black');
        }
        if (jQuery('html').hasClass('negativeColor')) {
            jQuery(element).removeClass('negativeColor');
            element.classList.add('white_on_black');
        }
        Cookies.set('accessibilityColor', 'white_on_black');
    });
    if (Cookies.get('accessibilityColor')) {
        jQuery('html').addClass(Cookies.get('accessibilityColor'));
    }

    // Accessibilité reset font size
    function resetFontSize() {
        let htmlElt = document.querySelector("html");
        if (jQuery('html').hasClass('fontSizeMore1')) {
            jQuery(htmlElt).removeClass('fontSizeMore1');
        }
        if (jQuery('html').hasClass('fontSizeMore2')) {
            jQuery(htmlElt).removeClass('fontSizeMore2');
        }
        if (jQuery('html').hasClass('fontSizeMore3')) {
            jQuery(htmlElt).removeClass('fontSizeMore3');
        }
        if (jQuery('html').hasClass('fontSizeMore4')) {
            jQuery(htmlElt).removeClass('fontSizeMore4');
        }
        if (jQuery('html').hasClass('fontSizeMore5')) {
            jQuery(htmlElt).removeClass('fontSizeMore5');
        }
        clicks = 0;
        Cookies.remove('accessibilityFontSize')
    }
    

    // Accessibilité reset global
    let jsAccessibilityReset = document.querySelector('.jsAccessibilityReset');
    jsAccessibilityReset.addEventListener('click', function(e) {
        let bodyElt = document.querySelector("html");
        if (jQuery('html').hasClass('negativeColor')) {
            jQuery(bodyElt).removeClass('negativeColor');
        }
        if (jQuery('html').hasClass('white_on_black')) {
            jQuery(bodyElt).removeClass('white_on_black');
        }
        if (jQuery('html').hasClass('black_on_white')) {
            jQuery(bodyElt).removeClass('black_on_white');
        }
        resetFontSize();
        Cookies.remove('accessibilityColor')
    })
    
    if(jQuery('body').hasClass('is-beta') && jQuery('body').hasClass('site_46')) {
        let scroll = jQuery(window).scrollTop();
        jQuery(window).scroll(function () {
            scroll = jQuery(window).scrollTop();
            posHeader();
        });
        jQuery(window).resize(function () {
            scroll = jQuery(window).scrollTop();
            posHeader();
        });


        let position = jQuery(window).scrollTop();
        let keepHeaderVisible = false;
        let headerSticky = 0;
        let $header = jQuery('.header-page');
        let $headerContent = jQuery('.header-page-content');
        let headerHeight;

        function posHeader() {
            $header.css('height', $headerContent.outerHeight(true));
            headerHeight = $header.outerHeight(true);

            if (scroll == 0) {
                jQuery('body').removeClass('scroll-up scroll-down');
            } else if (scroll > position) {
                jQuery('body').addClass('scroll-down').removeClass('scroll-up');
            } else {
                jQuery('body').addClass('scroll-up').removeClass('scroll-down');
            }

            if (jQuery(document).width() > 1024) {
                if (scroll > $header.outerHeight(true) + $header.offset().top) {
                    headerSticky = $header.offset().top;
                    $header.addClass('transition');
                    keepHeaderVisible = true; // Garde le header visible jusqu'en haut de page
                } else if (keepHeaderVisible) {
                    headerSticky = $header.offset().top;
                    $header.addClass('transition');
                } else {
                    // Affiche le header progressivement suivant le scroll
                    headerSticky = $header.offset().top;
                    $header.removeClass('transition');
                }
                if (scroll <= $header.offset().top) {
                    headerSticky = scroll;
                    keepHeaderVisible = false;
                }

                $headerContent.css('transform', 'translateY(-' + headerSticky + 'px)');

                position = scroll;
            } else {
                $headerContent.css('transform', '');
            }
        }
        posHeader();
    }


    var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
    var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
        return new bootstrap.Tooltip(tooltipTriggerEl)
    });

    let backTop = document.querySelector('.back-top');

    if (backTop) {
        window.addEventListener('scroll', function () {
            if (window.scrollY > 1500) {
                backTop.classList.add('is-visible');
            } else {
                backTop.classList.remove('is-visible');
            }
        });
        backTop.addEventListener('click', function () {
            window.scrollTo(0, 0);
        });
    }

    let navBurger = document.querySelector('.nav-burger');
    navBurger.addEventListener('click', function () {
        document.body.classList.add('nav-is-toggled');
        document.querySelector('.mask').classList.add('is-visible');
    });

    let mask = document.querySelector('.mask');
    mask.addEventListener('click', function () {
        if (mask.classList.contains('is-visible')) {
            mask.classList.remove('is-visible');
            document.body.classList.remove('nav-is-toggled');
        }
    });


    const navExpand = [].slice.call(document.querySelectorAll('.nav-expand'))
    const backLink = `<li class="nav-item">
      <a class="nav-link nav-back-link" href="javascript:;">
        Retour
      </a>
    </li>`;

    const a = document.querySelectorAll('.nav-expand-link');
    const i = `<i class="fas fa-chevron-right"></i>`;



    navExpand.forEach(item => {
        item.querySelector('.nav-expand-content').insertAdjacentHTML('afterbegin', backLink)
        item.querySelector('.nav-link').addEventListener('click', () => item.classList.add('active'))
        if (item.querySelector('.nav-link > span') != null) {
            item.querySelector('.nav-link').insertAdjacentHTML('beforeend', i)
        }


        item.querySelector('.nav-back-link').addEventListener('click', () => item.classList.remove('active'))
    });

    const ham = document.getElementById('ham');
    const mobileBarBtnMenu = document.querySelector('.mobile-bar-btn-menu');
    if (ham) {
        ham.addEventListener('click', function () {
            document.body.classList.toggle('nav-is-toggled')
            $('.mask').addClass('is-visible');
        });
    }



    var homeHeroSlider = new Swiper(".home-hero-swiper", {
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
        loop: true,
        autoplay: {
           delay: 5000,
        },
    });
    var homeNewsSlider = new Swiper(".home-news-swiper", {
        slidesPerView: 1,
        spaceBetween: 30,
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
        autoHeight: true,
        loop: true,
        breakpoints: {
            576: {
                slidesPerView: 2,
            },
            991: {
                slidesPerView: 3,
            },
        },
    });

    jQuery('.home-agenda-swiper').addClass('nbr-posts-' + jQuery('.home-agenda-swiper .swiper-slide').length);
    var homeAgendaSlider = new Swiper(".home-agenda-swiper", {
        slidesPerView: 1,
        spaceBetween: 30,
        centerInsufficientSlides: true,
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
        breakpoints: {
            576: {
                slidesPerView: 2,
            },
            768: {
                slidesPerView: 3,
            },
            991: {
                slidesPerView: 4,
            },
        },
        autoHeight: true,
        loop: false,
    });


    let distance = '60px';
    ScrollReveal().reveal('.reveal-top', {reset: false, origin: 'top', distance: distance, duration: 500, easing: 'ease' });
    ScrollReveal().reveal('.reveal-right', {reset: false, origin: 'right', distance: distance, duration: 500, easing: 'ease' });
    ScrollReveal().reveal('.reveal-bottom', {reset: false, origin: 'bottom', distance: distance, duration: 500, easing: 'ease' });
    ScrollReveal().reveal('.reveal-left', {reset: false, origin: 'left', distance: distance, duration: 500, easing: 'ease' });

    // Vertical Scroll Bar
    function progressBarScroll()
    {
        let winScroll = document.body.scrollTop || document.documentElement.scrollTop,
            height = document.documentElement.scrollHeight - document.documentElement.clientHeight,
            scrolled = (winScroll / height) * 100;
        document.querySelector(".horizontal-progress-bar").style.width = scrolled + "%";
    }

        window.onscroll = function () {
            progressBarScroll();
        };


});
